@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.details
    &__search
        padding-top: 125px !important

    &__container
        padding-bottom: 50px

    &__upperhead
        margin-top: 25px
        display: grid
        grid-gap: 18px

        +desktop
            display: flex
            justify-content: space-between

    &__head
        margin-top: 50px

        +desktop
            margin-top: 25px

    &__nav
        display: flex
        grid-gap: 18px

    &__content
        margin-top: 30px
        display: grid
        grid-gap: 35px
        align-items: flex-start

        +fullhd
            grid-template-columns: auto 575px

    &__blocks
        margin-top: 25px
        display: grid
        grid-gap: 18px

        

        

        +desktop
            grid-template-columns: 280px auto

            background: #FFF
            padding: 10px 10px 20px 10px

            +contrast
                background: transparent

    &__aside
        background: #FFF

        +contrast
            background-color: $contrast-black

    &__info
        display: grid
        grid-gap: 55px

    &__photo
        min-height: 300px
        display: flex
        align-items: center
        justify-content: center

        &--img
            max-width: 100%

            &--not-found
                width: 75%

    &__sidebar
        display: grid
        grid-gap: 40px

    &__institution
        border-top: 1px solid #CCC
        border-bottom: 1px solid #CCC
        padding: 20px 0
        display: grid
        grid-gap: 20px

        +desktop
            display: flex
            justify-content: space-between
            align-items: center

        &--left
            +desktop
                padding: 0 18px

        + .details__box
            margin-top: 30px

    &__institution
        a, button
            padding-left: 20px
            padding-right: 20px


    &__box
        +desktop
            padding: 0 18px

    &__list
        margin-top: 15px
        display: grid
        grid-gap: 10px

    &__addItemAsTopic
        margin: 15px 15px 0

        button
            text-align: left

    &__addItemToCollection
        margin: 15px 0

        button
            width: 100%
            font-size: 0.875rem
            padding: 15px 25px

    &__collections
        margin-top: 15px

    &__map
        margin-top: 30px
        border: 1px solid #CCC

    &__modules
        margin-top: 25px
        display: grid
        grid-gap: 10px

    &__link 
        &--external 
            display: block

    &__module
        &--bad
            .details__module--head
                background: #0B8087

                +contrast
                    background: #000
                    

        &--agr
            .details__module--head
                background: #00598A

                +contrast
                    background: #000

        &--vol
            .details__module--head
                background: #161E66

                +contrast
                    background: #000

        &--head
            padding: 30px 25px

        &--content
            padding: 30px 25px
            background: #FFF

            +contrast
                background-color: $contrast-black

        &--list
            display: grid
            grid-gap: 30px
            margin-bottom: 20px

        &--item
            display: grid
            grid-gap: 5px

    &__text
        &--title
            @extend .text--heading-main

        &--heading
            @extend .text--heading-sub
            @extend .text--color-anthracit

        &--subtitle
            @extend .text--title

        &--subheading
            @extend .text--body
            @extend .text--color-anthracit
            margin-top: 10px

        &--item
            @extend .text--body
            @extend .text--color-anthracit

            +contrast
                color: $contrast-white

            strong
                @extend .text--color-black
                font-weight: inherit

                +contrast
                    color: $contrast-white

            a
                color: #005CA9

        &--collections
            @extend .text--body
            font-weight: 500
            display: flex
            justify-content: space-between

            +desktop
                padding: 0 15px

            +contrast
                background-color: $contrast-black

            &--header
                font-weight: 300
                padding: 0 15px

            &--link
                &:hover
                    text-decoration: underline !important

            &--delete
                visibility: hidden

                &:hover
                    text-decoration: none !important

            &:hover
                background-color: #DFEAF4

                .details__text--collections--delete
                    visibility: visible

        &--module
            &-heading
                @extend .text--heading-sub
                color: #FFF
                max-width: 250px

            &-title
                a
                    @extend .text--title

            &-content
                @extend .text--body
                @extend .text--color-dark

                +contrast
                    color: $contrast-white
//do przeniesienia jak będą kolekcje
.collection
    &__list
        display: grid
        grid-gap: 10px
        margin-top: 25px

    &__item
        background: #F9F9F9
        padding: 30px
        display: grid
        grid-template-columns: 80px auto
        grid-gap: 20px

        +contrast
            background-color: $contrast-black
            color: $contrast-white

        +desktop
            grid-template-columns: 350px auto
            grid-gap: 30px

    &__pic
        &--img
            max-width: 350px
            height: auto
            width: 100%
            background: #CCC

            +desktop
                max-width: 350px
                height: auto
                width: 100%

    &__content
        display: grid
        grid-gap: 30px

    &__more
        display: flex
        justify-content: flex-end
        align-items: flex-end

    &__metadata
        margin-top: 15px
        display: grid
        grid-gap: 5px

    &__description
        margin-top: 15px

    &__text
        &--title
            @extend .text--title
            @extend .text--color-blue

        &--metadata
            @extend .text--body
            @extend .text--color-anthracit

            +contrast
                color: $contrast-white

            strong
                @extend .text--color-black
                font-weight: inherit

                +contrast
                    color: $contrast-white

        &--description
            @extend .text--body
