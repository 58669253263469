@import '@/styles/_breakpoints.sass'
@import '@/styles/_mixins.sass'
@import '@/styles/_text.sass'
@import '@/styles/_contrast.sass'

.results-list
    padding-bottom: 150px

    &__header
        display: flex
        gap: 28px
        align-items: baseline
        flex-wrap: wrap
        justify-content: flex-end

    &__heading
        flex: 1 1 100%

        +fullhd
            flex: inherit

    &__nav
        flex: 1
        display: flex
        gap: 28px
        align-items: center

        +touch
            flex: 1 1 100%
            flex-direction: column
            align-items: flex-start

    &__footer
        margin-top: 30px
        display: flex
        justify-content: flex-end

    &__empty
        margin-top: 30px
        display: flex

        img
            margin-right: 1rem

        p
            margin-top: 1rem
            font-weight: bold
            white-space: nowrap
            word-spacing: 2px
            letter-spacing: 1px
            margin-bottom: 1rem

        small
            font-size: small
            white-space: nowrap

    &__list
        display: grid
        grid-gap: 10px
        margin-top: 12px

    &__item
        background: #F9F9F9
        padding: 18px
        display: grid
        grid-template-columns: 80px auto
        grid-gap: 20px

        +contrast
            background-color: $contrast-black

        +tablet
            grid-template-columns: 132px auto min-content
            grid-gap: 28px

        &:hover, &:focus 
            box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2)

    &__pic
        &--img
            width: 80px
            height: 92px
            display: flex
            justify-content: center
            align-items: center

            +tablet
                width: 132px
                height: 152px

            img 
                width: auto
                height: auto
                max-width: 100%
                max-height: 100%

    &__content
        display: grid
        grid-gap: 30px

        +fullhd
            grid-template-columns: auto auto

    &__more
        display: flex
        align-items: flex-end

        &--mobile
            display: flex
            align-items: center
            flex-direction: column

    &__title
        color: #005CA9

    &__metadata
        margin-top: 20px
        display: flex
        gap: 0 10px
        flex-wrap: wrap

        +touch
            flex-direction: column

        &--item
            padding-right: 10px
            border-right: 1px solid #C0C0C0

            +touch
                border-right: 0

            &:last-child
                border-right: 0

    &__description
        margin-top: 20px

        +touch
            margin-bottom: 20px

    &__text
        &--heading
            @extend .text--heading-sub
            color: #4A4A4A
            line-height: 1.5625rem
            font-weight: 500

        &--title
            @extend .text--title
            @extend .text--color-blue

            +contrast
                color: $contrast-white

        &--metadata
            @extend .text--body
            @extend .text--color-anthracit
            overflow-wrap: anywhere

            +contrast
                color: $contrast-white

            strong
                @extend .text--color-black
                font-weight: inherit

                +contrast
                    color: $contrast-white

        &--description
            @extend .text--body
